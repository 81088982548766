@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #2199df;
  text-decoration: unset;
}

ul {
  margin-left: 1.5rem;
  list-style: none;
}

button,
input[type="submit"] {
  border: none;
  background: none;
  color: unset;
  cursor: pointer;
}



h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 1em 0;
}

/* general */

body {
  font-family: "Roboto", sans-serif;
}

table{
  text-align: center;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

th, td{
  border: solid 1px #c2c2c2;
}

thead{
  background-color: #e07c7c;
  border-bottom: #b91b1b;
}

td {
  vertical-align: medium;
}

.page {
  padding: 1rem;
}

.container {
  margin: 0 auto;
  max-width: 900px;
}

/* header */

#header{
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e02525;
}

#header .title{
  margin: 0;
  padding: 0 1rem;
  color: #ffffff
}

#header .auth-options{
  height: 100%;
  display: flex;
}

#header .auth-options button{
  padding: 0 1rem;
  background-color: #b91b1b;
  color: #ffffff;
  font-size: 1.1rem;
}

/* form */

.form label
.form input {
  display: block;
}
.form input {
  margin-bottom: 1rem;
}
.form label{
  margin-bottom: 0.2rem;
}
.form input[type="text"],
.form input[type="email"],
.form input[type="password"] {
  width: 100%;
  padding: 0.3rem;
  border: 1px solid #c2c2c2;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 1.1rem;  
}
.form input:focus {
  border: 1px solid #268ee4;
  box-shadow: inset 0px 0px 10px rgba(38, 142, 228, 0.5);
  outline: none;
}
.form input[type="submit"]{
  padding: 0.3rem 0.5rem;
  border: 1px solid #9e9e9e;
  border-radius: 8px;
  background-color: #bdbdbd;
  font-size: 1.1rem;
}

/* error notice */
.error-notice{
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}
.error-notice button{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

/* media queries */

#header .auth-options button:hover{
  background-color: #be3a3a;
}

@media screen and (max-width: 550px){
  #header .title{
    font-size: 1.2rem;
  }
  #header .auth-options button{
    padding: 0 0.5rem;
    font-size: 0.9rem; 
  }
}

.fileContent{
  height: 300px;
  overflow: auto;
  background: #EEE;
  color: #666;
}
